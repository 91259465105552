import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

/* Global Config */
import './assets/extension'

/* 3rd Party Plugins */
import '@libs/alias'
import '@libs/vue-select'
import '@libs/vee-validate'
import '@libs/v-tooltip'
import '@libs/loading-overlay'
import '@libs/tinymce'
import '@libs/moment'
import '@libs/sweetalert'
import nprogress from '@libs/nprogress'
import VCalendar from 'v-calendar';
import { BootstrapVue } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(VCalendar)

import money from 'v-money'
 // register directive v-money and component <money>
Vue.use(money, {
  decimal: ',', thousands: '.', 
  prefix: '', suffix: '', 
  precision:0, masked: false
})

store.dispatch('watchAuth').then(() => {
  new Vue({
    nprogress,
    router,
    store,
    data() {
      return {
        var: { loadingOverlay: false },
        app: { uploader: {} },
      }
    },
    render: h => h(App),
  }).$mount('#app')
})
