export default [
  /**
   * NO AUTH
   */
  {
    path: '/login',
    name: 'Login',
    component: () => import('@view/Login.vue'),
    meta: {
      redirectIfLoggedIn: true,
      menu:'Login'
    },
  },

  /**
   * WITH AUTH
   */
  {
    path: "",
    component: () => import('@view/include/Layout.vue'),
    redirect: { name: "Dashboard" },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@view/Dashboard.vue'),
        meta: {
          menu: "Dashboard",
          pageTitle:'Dashboard',
        },
      },
      {
        path: '/pre-reg-registrant/:pageSlug?',
        name: 'PreRegistration',
        component: () => import('@view/PreRegistration.vue'),
        meta: {
          menu: "PreRegistration",
          pageTitle: 'Pre-Registration',
        },
      },
      {
        path: '/pages/home',
        name: 'Home',
        component: () => import('@view/Home.vue'),
        meta: {
          menu: "Home",
          pageTitle: 'Home',
        },
      },
      {
        path: '/pages/about-klab',
        name: 'AboutKlab',
        component: () => import('@view/AboutKlab.vue'),
        meta: {
          menu: "AboutKlab",
          pageTitle: 'About KLAB',
        },
      },
      {
        path: '/pages/race-pack-collection',
        name: 'RacePackCollection',
        component: () => import('@view/RacePackCollection.vue'),
        meta: {
          menu: "RacePackCollection",
          pageTitle: 'Race Pack Collection',
        },
      },
      {
        path: '/pages/race-expo',
        name: 'RaceExpo',
        component: () => import('@view/RaceExpo.vue'),
        meta: {
          menu: "RaceExpo",
          pageTitle: 'Race Expo',
        },
      },
      {
        path: '/pages/race-guide',
        name: 'RaceGuide',
        component: () => import('@view/RaceGuide.vue'),
        meta: {
          menu: "RaceGuide",
          pageTitle: 'Race Guide',
        },
      },
      {
        path: '/pages/insurance-protection',
        name: 'InsuranceProtection',
        component: () => import('@view/InsuranceProtection.vue'),
        meta: {
          menu: "InsuranceProtection",
          pageTitle: 'Insurance Protection',
        },
      },
      {
        path: '/pages/official-sponsor/:pageSlug?',
        name: 'OfficialSponsor',
        component: () => import('@view/OfficialSponsor.vue'),
        meta: {
          menu: "OfficialSponsor",
          pageTitle: 'Official Sponsor',
        },
      },
      {
        path: '/pages/media-partner/:pageSlug?',
        name: 'MediaPartner',
        component: () => import('@view/MediaPartner.vue'),
        meta: {
          menu: "MediaPartner",
          pageTitle: 'Media Partner',
        },
      },
      {
        path: '/pages/blog/:pageSlug?',
        name: 'Blog',
        component: () => import('@view/Blog.vue'),
        meta: {
          menu: "Blog",
          pageTitle: 'Blog',
        },
      },
      {
        path: '/pages/contact/:pageSlug?',
        name: 'Contact',
        component: () => import('@view/Contact.vue'),
        meta: {
          menu: "Contact",
          pageTitle: 'Contact',
        },
      },
      {
        path: '/pages/web-settings/:pageSlug?',
        name: 'WebSettings',
        component: () => import('@view/WebSettings.vue'),
        meta: {
          menu: "WebSettings",
          pageTitle: 'Web Settings',
        },
      },
      {
        path: '/pages/legal/terms-conditions/:pageSlug?',
        name: 'TermsConditions',
        component: () => import('@view/TermsConditions.vue'),
        meta: {
          menu: "TermsConditions",
          pageTitle: 'Terms Conditions',
        },
      },
      {
        path: '/pages/legal/privacy-policy/:pageSlug?',
        name: 'PrivacyPolicy',
        component: () => import('@view/PrivacyPolicy.vue'),
        meta: {
          menu: "PrivacyPolicy",
          pageTitle: 'Privacy Policy',
        },
      },
      {
        path: '/pages/template/:pageSlug?',
        name: 'Template',
        component: () => import('@view/Template.vue'),
        meta: {
          menu: "Template",
          pageTitle: 'Template',
        },
      },
      {
        path: '/user-management/user-list/:pageSlug?',
        name: 'Users',
        component: () => import('@view/Users.vue'),
        meta: {
          menu: "Users",
          pageTitle: 'User',
        },
      },
      {
        path: '/user-management/user-roles/:pageSlug?',
        name: 'UserLevel',
        component: () => import('@view/UserLevel.vue'),
        meta: {
          menu: "UserLevel",
          pageTitle: 'User Level',
        },
      },
      {
        path: '/audit-trail',
        name: 'AuditTrail',
        component: () => import('@view/AuditTrail.vue'),
        meta: {
          menu: "AuditTrail",
          pageTitle: 'Audit Trail',
        },
      },
      {
        path: '/master/gallery-klab/:pageSlug?',
        name: 'GalleryKlab',
        component: () => import('@view/GalleryKlab.vue'),
        meta: {
          menu: "GalleryKlab",
          pageTitle: 'Gallery Klab',
        },
      },
      {
        path: '/master/klab-team/:pageSlug?',
        name: 'KlabTeam',
        component: () => import('@view/KlabTeam.vue'),
        meta: {
          menu: "KlabTeam",
          pageTitle: 'Klab Team',
        },
      },
      {
        path: '/master/author/:pageSlug?',
        name: 'Author',
        component: () => import('@view/Author.vue'),
        meta: {
          menu: "Author",
          pageTitle: 'Author',
        },
      },
      {
        path: '/master/category-blog/:pageSlug?',
        name: 'CategoryBlog',
        component: () => import('@view/CategoryBlog.vue'),
        meta: {
          menu: "CategoryBlog",
          pageTitle: 'Category Blog',
        }
      },
      {
        path: '/master/category-partner/:pageSlug?',
        name: 'CategoryPartner',
        component: () => import('@view/CategoryPartner.vue'),
        meta: {
          menu: "CategoryPartner",
          pageTitle: 'Category Partner',
        }
      },
      {
        path: '/pages/event/:pageSlug?',
        name: 'Event',
        component: () => import('@view/Event.vue'),
        meta: {
          menu: "Event",
          pageTitle: 'Event Category',
        },
      },
    ],
  },
]
