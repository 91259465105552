import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import store from '@/store/index'
import bo_route from './bo_route'

Vue.use(VueRouter)

const state = store.state;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'Dashboard' } },
    ...bo_route,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {

  const isLoggedIn = store.getters['authenticated']
  const user = state.user

  if (to.meta.menu) {
    store.dispatch('watchMeta').then(() => {
      if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        // Redirect if logged in
        return next({ name: 'Dashboard' })
      } else if (!to.meta.redirectIfLoggedIn && !isLoggedIn) {
        // Redirect if not logged in
        return next({ name: 'Login' })
      } else if (isLoggedIn && to.meta.menu && (user.menuRole || []).indexOf(to.meta.menu) <= -1) {
        // login AND not allowed menu > to first allowed menu
        if (store.getters['canManageAll']) {
          return next()
        } else {
          return next({ name: user.menuRole[0] })
        }
      }

      return next()
    })
  }else{
    return next()
  }
})

export default router
