<template>
  <fragment>
    <router-view></router-view>
    <nprogress-container></nprogress-container>
    <ImageFilter />
    <LoadingOverlay :active.sync="loadingOverlay" :is-full-page="true" />
  </fragment>
</template>

<script>
import Vue from 'vue'
import GlobalVue from '@libs/Global.vue'
import Gen from '@libs/Gen'
import $ from 'jquery'
import _ from 'lodash'
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import Fragment from 'vue-fragment'

global._ = _
global.jQuery = $
global.$ = $

Vue.use(Fragment.Plugin)

export default{
  extends:GlobalVue,
  components:{NprogressContainer},
  created(){
    /*
      ----------------------------
        JS/CSS GLOBAL
      ----------------------------
    */
    Gen.loadScriptCSS("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap");
    
    Gen.loadScriptJS("/js/bootstrap/dist/js/bootstrap.bundle.min.js");
    Gen.loadScriptJS("/js/popper/popper.min.js");
    Gen.loadScriptJS("/js/perfect-scrollbar.jquery.min.js");
    Gen.loadScriptJS("/js/waves.js");
    Gen.loadScriptJS("/js/sidebarmenu.js");
    Gen.loadScriptJS("/js/sticky-kit-master/dist/sticky-kit.min.js");
    Gen.loadScriptJS("/js/sparkline/jquery.sparkline.min.js");
    Gen.loadScriptJS("/js/jquery-sparkline/jquery.sparkline.min.js");
    // Gen.loadScriptJS("/js/custom.min.js");
    Gen.loadScriptJS("/js/custom.js");
    
    this.refreshVariable()
  },
  methods:{
    refreshVariable(){
      var variable = Gen.getStorage("bo_variable", {});
      _.forEach(variable, (v,k)=>{ this.$root[k] = v })
      Gen.apiRest("/variable", {}).then((res)=>{
        Gen.putStorage("bo_variable", res.data)
        _.forEach(res.data, (v,k)=>{ this.$set(this.$root, k, v) })
      })
    },
  }
}
</script>

<style lang="scss">
@import "assets/global.scss";
@import "assets/css/style.min.css";
@import "assets/css/custom_l9.css";
</style>