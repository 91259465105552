const Config = {
  app: {
    webaname: '{WEBNAME}',
    company: '{WEBNAME}',
    copyright: () => {
      let date = new Date()
      if (date.getFullYear() > 2021) {
        return '2021-' + date.getFullYear()
      } else {
        return '2021'
      }
    }
  },
  encrypt_key:"#!JRF%"
};
export default Config;